<template>
    <div class="payment-container">
        <iframe style="width: 100%;z-index: 1090; border: unset" _kpayment class="kpayment-m-top-hide "
            :src="'/Payment-Gateway/embeddedKBankQr.html?order_id=' + order_id + '&receiptObjectId=' + receiptObjectId + '&uid=' + uid + '&shopObjectId=' + shopObjectId + '&locale=' + locale + '&dashboardUrl=' + dashboardUrl"></iframe>
    </div>
</template>

<script>
import axios from 'axios';
import { locale } from 'moment';

export default {
    props: ['docTotal', 'pKey'],
    data() {
        return {
            showPaymentButton: false,
            ElementScript: null,
            order_id: '',
            receiptObjectId: '',
            locale: '',
            dashboardUrl: process.env.VUE_APP_WEB_DASHBOARD_URL
        };
    },
    created() {
        this.receiptObjectId = localStorage.getItem('receiptObjectId') || ''
        this.locale = this.$i18n.locale
        this.createOrder();
    },
    computed: {
        uid() {
            return `${localStorage.getItem('uid')}`
        },
        shopObjectId() {
            return `${localStorage.getItem('shopObjectId')}`
        },
    },
    methods: {
        async createOrder() {
            let orderData = {
                amount: parseFloat(this.docTotal).toFixed(2),
                currency: 'THB',
                description: "",
                source_type: 'qr',
                reference_order: this.receiptObjectId, //receipt objectId
                ref_1: this.uid, //uid
                ref_2: this.shopObjectId, // shop objectId
            };
            try {
                await axios.post(process.env.VUE_APP_PAYMENT_GATEWAY_URL + "/embedded/v1.0/kbank/order", orderData)
                    .then(response => {
                        this.order_id = response.data.data.id
                    })
                    .catch(error => {
                        console.error('Error:', error);
                    });
            } catch (error) {
                throw new Error(error)
            }



        }

    }
}
</script>
<style scoped>
.payment-container {
    display: flex;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 1090;
}

.modal-body {
    padding: 0rem;
}
</style>